
import { defineComponent } from 'vue';
import { CloseCircleOutlined, RightOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    CloseCircleOutlined,
    RightOutlined,
  },
});
